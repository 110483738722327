import Ellipse1182 from '@/assets/messageDemo/Ellipse1182.jpg';
import Ellipse1178 from '@/assets/messageDemo/Ellipse1178.jpg';
import Ellipse30 from '@/assets/messageDemo/Ellipse30.jpg';
import Ellipse28 from '@/assets/messageDemo/Ellipse28.jpg';
import Ellipse12 from '@/assets/messageDemo/Ellipse12.jpg';
import Group3466340 from '@/assets/messageDemo/Group3466340.jpg';
import Ellipse14 from '@/assets/messageDemo/Ellipse14.jpg';
import Ellipse20 from '@/assets/messageDemo/Ellipse20.jpg';
import Ellipse22 from '@/assets/messageDemo/Ellipse22.jpg';
import Ellipse24 from '@/assets/messageDemo/Ellipse24.jpg';
import Ellipse26 from '@/assets/messageDemo/Ellipse26.jpg';
import Ellipse31 from '@/assets/messageDemo/Ellipse31.jpg';

export const jsonDataChatSession = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 9,
      length: 9,
      chat_sessions: [
        {
          title: 'Bonnie',
          avatar_url: Ellipse1182,
          chat_type: 1,
          last_message: 'Yeah, it sounds like a dream. ',
          last_time: 1671701340,
          chat_session_id: 'de-mo-c-id-1018',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1018',
        },
        {
          title: 'Mike',
          avatar_url: Ellipse1178,
          chat_type: 1,
          last_message:
            "No problem. I'm always happy to share beautiful things with my friends.",
          last_time: 1671698340,
          chat_session_id: 'de-mo-c-id-1019',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1019',
        },
        {
          title: 'Hailey Levine',
          avatar_url: Ellipse28,
          chat_type: 1,
          last_message: 'Ok. Nighty night.',
          last_time: 1668547980,
          chat_session_id: 'de-mo-c-id-1001',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1001',
        },
        {
          title: 'Luke Adler',
          avatar_url: Ellipse30,
          chat_type: 1,
          last_message: 'hahaha. 100% agreed.',
          last_time: 1668445020,
          chat_session_id: 'de-mo-c-id-1002',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1002',
        },
        {
          title: 'Claire',
          avatar_url: Ellipse12,
          chat_type: 1,
          last_message: 'Hey man',
          last_time: 1668362760,
          chat_session_id: 'de-mo-c-id-1004',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1004',
        },
        {
          title: 'Pre holidayers',
          avatar_url: Group3466340,
          chat_type: 2,
          last_message: 'Got it.',
          last_time: 1668359160,
          chat_session_id: 'de-mo-c-id-1003',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1003',
        },
        {
          title: 'Ariana Harris',
          avatar_url: Ellipse14,
          chat_type: 1,
          last_message: 'Ok, see ya then.',
          last_time: 1668272760,
          chat_session_id: 'de-mo-c-id-1005',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1005',
        },
        {
          title: 'Andrew Smith',
          avatar_url: Ellipse20,
          chat_type: 1,
          last_message: 'Sup bro',
          last_time: 1668099960,
          chat_session_id: 'de-mo-c-id-1006',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1006',
        },
        {
          title: 'Liam Jordan',
          avatar_url: Ellipse22,
          chat_type: 1,
          last_message: '[video]',
          last_time: 1668011940,
          chat_session_id: 'de-mo-c-id-1007',
          unique_id: 'demo-device-dddd0002de-mo-c-id-1007',
        },
      ],
      update_time: 1697593916,
    },
  },
};

export const jsonDataMessage1 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 7,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Yeah, it sounds like a dream. ',
          attach_content: '',
          date: 1671701340,
        },
        {
          avatar_url: Ellipse1182,
          send_side: 2,
          sender: 'Bonnie',
          type: 1,
          content:
            ' Me too.  It would be so nice to relax on the beach and watch the sunset every evening.',
          attach_content: '',
          date: 1671701340,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: "I've always wanted to go on a beach vacation.",
          attach_content: '',
          date: 1671701220,
        },
        {
          avatar_url: Ellipse1182,
          send_side: 2,
          sender: 'Bonnie',
          type: 1,
          content: "I think it's somewhere in the Caribbean.",
          attach_content: '',
          date: 1671701220,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Wow, that is gorgeous! Where was it taken?',
          attach_content: '',
          date: 1671701100,
        },
        {
          avatar_url: Ellipse1182,
          send_side: 2,
          sender: 'Bonnie',
          type: 2,
          attach_content: '',
          date: 1671700920,
          oss_key: 'demo/images/ada99682-8592-11ed-befa-e4a8df920d55.jpeg',
          file_name: 'image.jpg',
          size: 271,
          file_url:
            'https://parentalguard-test.afirstsoft.cn/oss/proxy/demo%2Fimages%2Fada99682-8592-11ed-befa-e4a8df920d55.jpeg',
        },
        {
          avatar_url: Ellipse1182,
          send_side: 2,
          sender: 'Bonnie',
          type: 1,
          content:
            "Hey, have you seen this picture of the sunset on the beach? It's so beautiful!",
          attach_content: '',
          date: 1671700920,
        },
      ],
    },
  },
};

export const jsonDataContacts = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      length: 12,
      contacts: [
        {
          label: 'A',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww8',
              name: 'Aden Moore',
              email: 'Kelsi22@gmail.com',
              phone: '909-826-1623',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww16',
              name: 'Alex',
              email: 'Alex47@hotmail.com',
              phone: '897-533-3515',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww6',
              name: 'Andrew Smith',
              email: 'Deon46@hotmail.com',
              phone: '370-467-0638',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww20',
              name: 'Anna',
              email: 'Anna78@gmail.com',
              phone: '897-533-3519',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww4',
              name: 'Ariana Harris',
              email: 'Bradford_Gislason@yahoo.com',
              phone: '355-807-4362',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww11',
              name: 'Axel',
              email: 'Myrtie41@hotmail.com',
              phone: '897-522-3518',
            },
          ],
        },
        {
          label: 'B',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww17',
              name: 'Bella',
              email: 'Bella1@hotmail.com',
              phone: '653-345-3516',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww9',
              name: 'Blake Mattews',
              email: 'Erwin.Schiller@gmail.com',
              phone: '464-870-9153',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww26',
              name: 'Bonnie',
              email: 'Bonnie54@hotmail.com',
              phone: '823-533-3625',
            },
          ],
        },
        {
          label: 'C',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww10',
              name: 'Cameron',
              email: 'Kellen28@hotmail.com',
              phone: '342-228-4973',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww3',
              name: 'Claire',
              email: 'Eleanore.Leannon11@hotmail.com',
              phone: '863-769-9014',
            },
          ],
        },
        {
          label: 'E',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww5',
              name: 'Esther',
              email: 'Hazel.Paucek7@hotmail.com',
              phone: '481-974-7584',
            },
          ],
        },
        {
          label: 'H',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww13',
              name: 'Hailey Levine',
              email: 'Rapha7@gmail.com',
              phone: '934-462-3698',
            },
          ],
        },
        {
          label: 'J',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww19',
              name: 'Jack',
              email: 'Jack7@gmail.com',
              phone: '897-533-3518',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww25',
              name: 'Jane',
              email: 'Jane8@gmail.com',
              phone: '823-533-3884',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww15',
              name: 'Jules',
              email: 'Jules4@hotmail.com',
              phone: '897-522-3514',
            },
          ],
        },
        {
          label: 'L',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww7',
              name: 'Liam Jordan',
              email: 'Arlo_McClure@hotmail.com',
              phone: '323-521-4922',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww14',
              name: 'Luke Adler',
              email: 'haelle78@gmail.com',
              phone: '934-462-3699',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww23',
              name: 'Luna',
              email: 'Luna6@gmail.com',
              phone: '897-533-3522',
            },
          ],
        },
        {
          label: 'M',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww18',
              name: 'Mia',
              email: 'Mia76@gmail.com',
              phone: '764-346-3577',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww27',
              name: 'Mike',
              email: 'Mike47@hotmail.com',
              phone: '865-533-3426',
            },
          ],
        },
        {
          label: 'N',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww12',
              name: 'Nelia Justo',
              email: 'Raphaelle76@gmail.com',
              phone: '934-462-3697',
            },
          ],
        },
        {
          label: 'P',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww22',
              name: 'Paul',
              email: 'Paul47@hotmail.com',
              phone: '456-832-3421',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww24',
              name: 'Peter',
              email: 'Peter7@gmail.com',
              phone: '897-533-3523',
            },
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww2',
              name: 'Peyton Dawson',
              email: 'Lela_Erdman28@gmail.com',
              phone: '889-712-9454',
            },
          ],
        },
        {
          label: 'S',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww1',
              name: 'Sean Heck',
              email: 'Rodolfo.Parker7@hotmail.com',
              phone: '376-403-7602',
            },
          ],
        },
        {
          label: 'T',
          children: [
            {
              app: '',
              id: 'demo-device-dddd0002-demo-contact-ww21',
              name: 'Tom',
              email: 'Tom4@hotmail.com',
              phone: '897-533-3520',
            },
          ],
        },
      ],
      update_time: 1697593917,
      total: 27,
    },
  },
};

export const jsonDataCalls = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 9,
      length: 9,
      calls: [
        {
          contact_name: 'Sean Heck',
          date: 1668547980,
          status: 'outgoing',
          count: 1,
        },
        {
          contact_name: 'Peyton Dawson',
          date: 1668445020,
          status: 'no answered',
          count: 5,
        },
        {
          contact_name: 'Ariana Harris',
          date: 1668362760,
          status: 'missed',
          count: 11,
        },
        {
          contact_name: 'Claire',
          date: 1668359160,
          status: 'incoming',
          count: 6,
        },
        {
          contact_name: 'Aden Moore',
          date: 1668357660,
          status: 'missed',
          count: 4,
        },
        {
          contact_name: 'Blake Mattews',
          date: 1668273000,
          status: 'outgoing',
          count: 1,
        },
        {
          contact_name: 'Esther',
          date: 1668272760,
          status: 'outgoing',
          count: 20,
        },
        {
          contact_name: 'Andrew Smith',
          date: 1668099960,
          status: 'no answered',
          count: 4,
        },
        {
          contact_name: 'Liam Jordan',
          date: 1668011940,
          status: 'incoming',
          count: 2,
        },
      ],
      update_time: 1697593917,
    },
  },
};
export const jsonDataMessage2 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 10,
      messages: [
        {
          avatar_url: Ellipse1178,
          send_side: 2,
          sender: 'Mike',
          type: 1,
          content:
            "No problem. I'm always happy to share beautiful things with my friends.",
          attach_content: '',
          date: 1671698340,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Agreed. Thanks for sharing the video with me.',
          attach_content: '',
          date: 1671698340,
        },
        {
          avatar_url: Ellipse1178,
          send_side: 2,
          sender: 'Mike',
          type: 1,
          content:
            "You should definitely do that. There's nothing like experiencing it in person.",
          attach_content: '',
          date: 1671698220,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content:
            "Definitely. I'll have to make sure to catch the sunset in person sometime soon.",
          attach_content: '',
          date: 1671698220,
        },
        {
          avatar_url: Ellipse1178,
          send_side: 2,
          sender: 'Mike',
          type: 1,
          content:
            "I know, right? I can't get enough of it. It's one of those moments that just takes your breath away.",
          attach_content: '',
          date: 1671698100,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content:
            "Wow, that's stunning. The colors are so vibrant and the reflections on the water are breathtaking.",
          attach_content: '',
          date: 1671698040,
        },
        {
          avatar_url: Ellipse1178,
          send_side: 2,
          sender: 'Mike',
          type: 3,
          attach_content: '',
          date: 1671697920,
          oss_key: 'demo/videos/00d04c62-8593-11ed-befa-e4a8df920d55.mp4',
          size: 281,
          file_url:
            'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo%2Fvideos%2F00d04c62-8593-11ed-befa-e4a8df920d55.mp4',
        },
        {
          avatar_url: Ellipse1178,
          send_side: 2,
          sender: 'Mike',
          type: 1,
          content: 'Sure, here it is.',
          attach_content: '',
          date: 1671697920,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: "No, I haven't. Can you send it to me?",
          attach_content: '',
          date: 1671697800,
        },
        {
          avatar_url: Ellipse1178,
          send_side: 2,
          sender: 'Mike',
          type: 1,
          content:
            ' Hey, have you seen the video of the sunset from last night? It was so beautiful.',
          attach_content: '',
          date: 1671697800,
        },
      ],
    },
  },
};
export const jsonDataMessage3 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 14,
      messages: [
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 1,
          content: 'Ok. Nighty night.',
          attach_content: '',
          date: 1668547980,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Talk to you tomorrow. Its too late. I gotta sleep.',
          attach_content: '',
          date: 1668547980,
        },
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 1,
          content: "That's great.",
          attach_content: '',
          date: 1668547740,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: '100% agreed!  I got his WhatsApp.',
          attach_content: '',
          date: 1668547620,
        },
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 2,
          attach_content: '',
          date: 1668547620,
          oss_key: 'demo/images/d51743ae-7b85-11ed-8730-e4a8df920d55.jpg',
          file_name: 'image.jpg',
          size: 154,
          file_url:
            'https://parentalguard-test.afirstsoft.cn/oss/proxy/demo%2Fimages%2Fd51743ae-7b85-11ed-8730-e4a8df920d55.jpg',
        },
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 1,
          content: "I took a pic of Wyatt. He's so hot.",
          attach_content: '',
          date: 1668547500,
        },
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 1,
          content: 'Yeah, who said no. hahahaha😄',
          attach_content: '',
          date: 1668547440,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: "THX for driving me back. That's a crazy party.",
          attach_content: '',
          date: 1668547320,
        },
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 1,
          content: 'Ok, see ya then.',
          attach_content: '',
          date: 1668514920,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Really? I will go definitely.',
          attach_content: '',
          date: 1668514800,
        },
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 1,
          content: 'BTW, Wyatt will come.',
          attach_content: '',
          date: 1668514800,
        },
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 1,
          content:
            'No worry, just slip out of the window and I will drive you back.',
          attach_content: '',
          date: 1668514620,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Yeah...but i am grounded. 😭',
          attach_content: '',
          date: 1668514500,
        },
        {
          avatar_url: Ellipse28,
          send_side: 2,
          sender: 'Hailey Levine',
          type: 1,
          content: 'Wanna go to the party tonight?',
          attach_content: '',
          date: 1668514500,
        },
      ],
    },
  },
};
export const jsonDataMessage4 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 12,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'hahaha. 100% agreed.',
          attach_content: '',
          date: 1668445020,
        },
        {
          avatar_url: Ellipse30,
          send_side: 2,
          sender: 'Luke Adler',
          type: 1,
          content:
            "That's a lot of expense. I'd better save money from now on.",
          attach_content: '',
          date: 1668445020,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content:
            'Yep. I have 3 kids. You know heir gifts, ornaments and other stuff need to buy each year.',
          attach_content: '',
          date: 1668444840,
        },
        {
          avatar_url: Ellipse30,
          send_side: 2,
          sender: 'Luke Adler',
          type: 1,
          content: 'Is it really? You need to buy so many things for Xmas?',
          attach_content: '',
          date: 1668444720,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 6,
          attach_content: '',
          date: 1668444600,
          oss_key: 'demo/files/077ef5a3-7b87-11ed-8730-e4a8df920d55.doc',
          file_name: 'shopping list for Xmas',
          size: 167,
          file_url:
            'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo%2Ffiles%2F077ef5a3-7b87-11ed-8730-e4a8df920d55.doc',
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content:
            'Ah. Kids. My wife just sent me a shopping list preparing for Christmas.',
          attach_content: '',
          date: 1668444180,
        },
        {
          avatar_url: Ellipse30,
          send_side: 2,
          sender: 'Luke Adler',
          type: 1,
          content:
            "Man, no worry about it. I can't believe that I have a family now and I will be a father in the future.",
          attach_content: '',
          date: 1668444180,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'i am honestly sorry',
          attach_content: '',
          date: 1668444060,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'I am sorry for not being there.',
          attach_content: '',
          date: 1668443940,
        },
        {
          avatar_url: Ellipse30,
          send_side: 2,
          sender: 'Luke Adler',
          type: 1,
          content: 'I am good. The wedding was finished just now.',
          attach_content: '',
          date: 1668443760,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Sup bro',
          attach_content: '',
          date: 1668443640,
        },
        {
          avatar_url: Ellipse30,
          send_side: 2,
          sender: 'Luke Adler',
          type: 1,
          content: 'Hey man',
          attach_content: '',
          date: 1668443640,
        },
      ],
    },
  },
};
export const jsonDataMessage5 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 1,
      messages: [
        {
          avatar_url: Ellipse12,
          send_side: 2,
          sender: 'Claire',
          type: 1,
          content: 'Hey man',
          attach_content: '',
          date: 1668362760,
        },
      ],
    },
  },
};
export const jsonDataMessage6 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 16,
      messages: [
        {
          avatar_url: Ellipse26,
          send_side: 2,
          sender: 'Alex',
          type: 1,
          content: 'Got it.',
          attach_content: '',
          date: 1668359160,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: '9:30 AM',
          attach_content: '',
          date: 1668359100,
        },
        {
          avatar_url: Ellipse26,
          send_side: 2,
          sender: 'Alex',
          type: 1,
          content: 'How about the time?',
          attach_content: '',
          date: 1668358860,
        },
        {
          avatar_url: Ellipse26,
          send_side: 2,
          sender: 'Alex',
          type: 1,
          content: 'Ooops. It slipped my mind. Wait a sec. I will book now.',
          attach_content: '',
          date: 1668358740,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Have you booked the ticket?',
          attach_content: '',
          date: 1668358620,
        },
        {
          avatar_url: Ellipse26,
          send_side: 2,
          sender: 'Alex',
          type: 1,
          content: 'Deal then！',
          attach_content: '',
          date: 1668358620,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 2,
          attach_content: '',
          date: 1668358440,
          oss_key: 'demo/images/b1f1264e-7d10-11ed-8730-e4a8df920d55.jpg',
          file_name: 'image.jpg',
          size: 182,
          file_url:
            'https://parentalguard-test.afirstsoft.cn/oss/proxy/demo%2Fimages%2Fb1f1264e-7d10-11ed-8730-e4a8df920d55.jpg',
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content:
            'There are many famous winery and golf resorts. I have been there like 7 or 8 years ago.',
          attach_content: '',
          date: 1668358320,
        },
        {
          avatar_url: Ellipse31,
          send_side: 2,
          sender: 'Jules',
          type: 1,
          content: "Nah. Money's tight. My budget is done for the month.😭",
          attach_content: '',
          date: 1668358200,
        },
        {
          avatar_url: Ellipse26,
          send_side: 2,
          sender: 'Alex',
          type: 1,
          content: 'Then come with us.',
          attach_content: '',
          date: 1668357780,
        },
        {
          avatar_url: Ellipse31,
          send_side: 2,
          sender: 'Jules',
          type: 1,
          content: 'The sunshine, palm tree and beach. I hope I can go there.',
          attach_content: '',
          date: 1668357780,
        },
        {
          avatar_url: Ellipse31,
          send_side: 2,
          sender: 'Jules',
          type: 1,
          content: 'Whoa. I have to say this is the very time to go to LA.',
          attach_content: '',
          date: 1668357660,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 3,
          attach_content: '',
          date: 1668357540,
          oss_key: 'demo/videos/7f127394-7d10-11ed-8730-e4a8df920d55.mp4',
          file_name: 'video.mp4',
          size: 176,
          file_url:
            'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo%2Fvideos%2F7f127394-7d10-11ed-8730-e4a8df920d55.mp4',
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'I have a friend there and he sent me a video about CA.',
          attach_content: '',
          date: 1668357360,
        },
        {
          avatar_url: Ellipse26,
          send_side: 2,
          sender: 'Alex',
          type: 1,
          content: 'idk. Maybe CA?',
          attach_content: '',
          date: 1668357240,
        },
      ],
    },
  },
};
export const jsonDataMessage7 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 6,
      messages: [
        {
          avatar_url: Ellipse14,
          send_side: 2,
          sender: 'Ariana Harris',
          type: 1,
          content: 'Ok, see ya then.',
          attach_content: '',
          date: 1668272760,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Really? I will go definitely.',
          attach_content: '',
          date: 1668272700,
        },
        {
          avatar_url: Ellipse14,
          send_side: 2,
          sender: 'Ariana Harris',
          type: 1,
          content: 'BTW, Wyatt will come.',
          attach_content: '',
          date: 1668272460,
        },
        {
          avatar_url: Ellipse14,
          send_side: 2,
          sender: 'Ariana Harris',
          type: 1,
          content:
            'No worry, just slip out of the window and I will drive you back.',
          attach_content: '',
          date: 1668272340,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Yeah...but i am grounded. 😭',
          attach_content: '',
          date: 1668272220,
        },
        {
          avatar_url: Ellipse14,
          send_side: 2,
          sender: 'Ariana Harris',
          type: 1,
          content: 'Wanna go to the party tonight?',
          attach_content: '',
          date: 1668272220,
        },
      ],
    },
  },
};
export const jsonDataMessage8 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 2,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Sup bro',
          attach_content: '',
          date: 1668099960,
        },
        {
          avatar_url: Ellipse20,
          send_side: 2,
          sender: 'Andrew Smith',
          type: 1,
          content: 'Hey man',
          attach_content: '',
          date: 1668099900,
        },
      ],
    },
  },
};
export const jsonDataMessage9 = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      total: 4,
      messages: [
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 3,
          attach_content: '',
          date: 1668011940,
          oss_key: 'demo/videos/7f127394-7d10-11ed-8730-e4a8df920d55.mp4',
          file_name: 'video.mp4',
          size: 205,
          file_url:
            'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo%2Fvideos%2F7f127394-7d10-11ed-8730-e4a8df920d55.mp4',
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'I have a friend there and he sent me a video about CA.',
          attach_content: '',
          date: 1668011760,
        },
        {
          avatar_url: Ellipse22,
          send_side: 2,
          sender: 'Liam Jordan',
          type: 1,
          content: 'idk. Maybe CA?',
          attach_content: '',
          date: 1668011640,
        },
        {
          avatar_url: Ellipse24,
          send_side: 1,
          sender: ' ',
          type: 1,
          content: 'Hi everyone, how will we spend the holiday next week?',
          attach_content: '',
          date: 1668011640,
        },
      ],
    },
  },
};
