
import {
  ref,
  defineComponent,
  nextTick,
  computed,
  onMounted,
  onUpdated,
} from "vue";
import { dateFormat, getFirstXLetters, formatFileName } from "@/utils/format";
import { useStore } from "vuex";
import { downloadFile, downloadDemoFile } from "@/utils/downloadFile";
import { chatType, sendSide } from "../config/chatConfig";
import { event } from "vue-gtag";
import { gaEvent } from "@/utils/format";

export default defineComponent({
  props: {
    layout: {
      type: String,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    fileStatus: {
      type: String,
      default: "loading",
    },
  },
  components: {
    // fullVideo
  },
  setup(props, context) {
    const store = useStore();

    const isVibe = computed(() => store.state.isVibe);
    const isDemoRef = computed(() => store.state.isDemo);

    const sessionClickItem = computed(() => store.state.sessionClickItem);
    // console.log(sessionClickItem.value, 'sessionClickItem');

    // 标识是否播放音频
    const isPlay = ref(false);
    let audio = new Audio();

    // 控制音频的播放与暂停
    const handleVoicePlay = (src: string) => {
      audio.src = src;
      isPlay.value = !isPlay.value;
      nextTick(() => {
        if (isPlay.value) {
          // 播放音频
          audio.play();
        } else {
          // 暂停音频
          audio.pause();
        }
      });
    };

    let downLoadProgressRef = ref(0);
    const showProgress = ref(false);
    const colors = [
      { color: "#f56c6c", percentage: 20 },
      { color: "#e6a23c", percentage: 40 },
      { color: "#5cb87a", percentage: 60 },
      { color: "#1989fa", percentage: 80 },
      { color: "#6f7ad3", percentage: 100 },
    ];

    // 标识是否播放视频
    const isPlayVideo = ref(false);
    const handleVideoPlay = () => {
      const videoElement = document.querySelector("#video") as HTMLMediaElement;
      isPlayVideo.value = !isPlayVideo.value;
      nextTick(() => {
        if (isPlayVideo.value) {
          // 播放音频
          videoElement.play();
        } else {
          // 暂停音频
          videoElement.pause();
        }
      });
    };

    // 处理视频加载失败
    const handleVideoError = () => {
      // console.log("handleVideoError");
    };

    const btnStatus = ref("default"); // 保存按钮的状态：default、focus、loading
    // const fileStatus = ref('loading') // 文件图片的状态：success、error、loading

    // 下载文件
    const downloadAnyFile = async (url: any, filename: string) => {
      try {
        store.commit("setDownloadNumber", store.state.downloadNumber + 1);
        gaEvent("Download_Number", {
          event_category: "WhatsApp_Function",
          event_label: store.state.downloadNumber,
        });

        const sourceArr = url.split("?");
        const suffixArr = sourceArr[0].split(".");
        const suffix = sourceArr[0].split(".")[suffixArr.length - 1];
        // console.log('suffix:', suffix);
        gaEvent("Download", {
          event_category: "WhatsApp_Function",
          event_label: suffix,
        });
        console.log("sourceArr[0].split:", sourceArr[0].split("."), suffix);

        btnStatus.value = "loading";
        showProgress.value = true;
        if (!isVibe.value) {
          await downloadDemoFile(url, 'Parentalguard.' + suffix, (downLoadProgress: any) => {
            // console.log('当前下载进度：', downLoadProgress);
            downLoadProgressRef.value = downLoadProgress;
            // 下载完成3s后隐藏进度条
            setTimeout(() => {
              showProgress.value = false;
            }, 100);
          });
        } else {
          await downloadFile(url, filename, (downLoadProgress: any) => {
            // console.log('当前下载进度：', downLoadProgress);
            downLoadProgressRef.value = downLoadProgress;
            // 下载完成3s后隐藏进度条
            setTimeout(() => {
              showProgress.value = false;
            }, 100);
          });
        }

        btnStatus.value = "default";
      } catch (err) {
        console.log(err);
      }
    };

    // 点击升级按钮触发
    const handleUpgradeClick = () => {
      store.commit("setUpgradeDialog", true);
    };

    // 对大图预览右上角关闭按钮进行修改
    const handleImageClick = (file_url: any, filename: string) => {
      // console.log('handleImageClick');
      setTimeout(() => {
        const rightTopDom = document.querySelector(
          ".el-image-viewer__close"
        ) as any;
        const closeImg = require("../../../assets/chat/close.svg");
        const downloadImg = require("../../../assets/chat/download.svg");
        rightTopDom.innerHTML = `<div style='display: flex'><img src="${downloadImg}" style='margin-right: 10px;' alt="" id='downloadImg'><img id='closeImg' src="${closeImg}" alt=""></div>`;
        const downloadImgDom = document.querySelector("#downloadImg");
        downloadImgDom?.addEventListener("click", (e) => {
          e.stopPropagation();
          // console.log('downloadImgDom');
          downloadAnyFile(file_url, filename);
        });
      }, 0);
    };

    const previewImg = ref();
    const handleDblClick = () => {
      // console.log("handleDblClick");
      previewImg.value.showViewer = true;
    };

    return {
      btnStatus,
      isVibe,
      // fileStatus,
      isDemoRef,
      dateFormat,
      isPlay,
      handleVoicePlay,
      handleUpgradeClick,
      handleVideoPlay,
      downloadAnyFile,
      downLoadProgressRef,
      colors,
      showProgress,
      getFirstXLetters,
      handleVideoError,
      formatFileName,
      handleImageClick,
      handleDblClick,
      previewImg,
      chatType,
      sendSide,
      sessionClickItem,
    };
  },
});
